<template>
  <div>
    <b-modal
      ref="modalReportVisita"
      :title="$t('Relatório da visita')"
      :cancel-title="$t('Fechar')"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-title="$t('Guardar')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="confirmSaveModal($event)"
      @cancel="hideModal"
    >
      <b-row class="">
        <b-col md="12">
          <h4>
            {{ $t('Apreciação') }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3 mt-1">
          <h5>
            {{ $t('Qualidade de construção') }}
          </h5>
        </b-col>
        <b-col
          class="col-3 col-md-2"
        >
          <b-form-group
            :label="$t('Mau')"
          >
            <b-form-checkbox
              v-model="valQualidadeConstrucao"
              :checked="valQualidadeConstrucao == 1"
              class="custom-control-danger"
              value="1"
              name="check-button"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          class="col-3 col-md-2 force-padding-left-05"
        >
          <b-form-group
            :label="$t('Médio')"
          >
            <b-form-checkbox
              v-model="valQualidadeConstrucao"
              :checked="valQualidadeConstrucao == 2"
              class="custom-control-warning"
              name="check-button"
              value="2"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          class="col-3 col-md-2 force-padding-left-05"
        >
          <b-form-group
            :label="$t('Bom')"
          >
            <b-form-checkbox
              v-model="valQualidadeConstrucao"
              :checked="valQualidadeConstrucao == 3"
              class="custom-control-primary"
              name="check-button"
              value="3"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          class="col-3 col-md-2 force-padding-0"
        >
          <b-form-group
            :label="$t('Muito bom')"
          >
            <b-form-checkbox
              v-model="valQualidadeConstrucao"
              :checked="valQualidadeConstrucao == 4"
              class="custom-control-success"
              name="check-button"
              value="4"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3 mt-1">
          <h5>
            {{ $t('Acabamentos') }}
          </h5>
        </b-col>
        <b-col class="col-3 col-md-2">
          <b-form-group
            :label="$t('Mau')"
          >
            <b-form-checkbox
              v-model="valAcabamentos"
              :checked="valAcabamentos == 1"
              class="custom-control-danger"
              name="check-button"
              value="1"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-left-05">
          <b-form-group
            :label="$t('Médio')"
          >
            <b-form-checkbox
              v-model="valAcabamentos"
              :checked="valAcabamentos == 2"
              class="custom-control-warning"
              name="check-button"
              value="2"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-left-05">
          <b-form-group
            :label="$t('Bom')"
          >
            <b-form-checkbox
              v-model="valAcabamentos"
              :checked="valAcabamentos == 3"
              class="custom-control-primary"
              name="check-button"
              value="3"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-0">
          <b-form-group
            :label="$t('Muito bom')"
          >
            <b-form-checkbox
              v-model="valAcabamentos"
              :checked="valAcabamentos == 4"
              class="custom-control-success"
              name="check-button"
              value="4"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3 mt-1">
          <h5>
            {{ $t('Exposição solar') }}
          </h5>
        </b-col>
        <b-col class="col-3 col-md-2">
          <b-form-group
            :label="$t('Mau')"
          >
            <b-form-checkbox
              v-model="valExposicaoSolar"
              :checked="valExposicaoSolar == 1"
              class="custom-control-danger"
              name="check-button"
              value="1"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-left-05">
          <b-form-group
            :label="$t('Médio')"
          >
            <b-form-checkbox
              v-model="valExposicaoSolar"
              :checked="valExposicaoSolar == 2"
              class="custom-control-warning"
              name="check-button"
              value="2"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-left-05">
          <b-form-group
            :label="$t('Bom')"
          >
            <b-form-checkbox
              v-model="valExposicaoSolar"
              :checked="valExposicaoSolar == 3"
              class="custom-control-primary"
              name="check-button"
              value="3"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-0">
          <b-form-group
            :label="$t('Muito bom')"
          >
            <b-form-checkbox
              v-model="valExposicaoSolar"
              :checked="valExposicaoSolar == 4"
              class="custom-control-success"
              name="check-button"
              value="4"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3 mt-1">
          <h5>
            {{ $t('Localização') }}
          </h5>
        </b-col>
        <b-col class="col-3 col-md-2">
          <b-form-group
            :label="$t('Mau')"
          >
            <b-form-checkbox
              v-model="valLocalizacao"
              :checked="valLocalizacao == 1"
              class="custom-control-danger"
              name="check-button"
              value="1"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-left-05">
          <b-form-group
            :label="$t('Médio')"
          >
            <b-form-checkbox
              v-model="valLocalizacao"
              :checked="valLocalizacao == 2"
              class="custom-control-warning"
              name="check-button"
              value="2"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-left-05">
          <b-form-group
            :label="$t('Bom')"
          >
            <b-form-checkbox
              v-model="valLocalizacao"
              :checked="valLocalizacao == 3"
              class="custom-control-primary"
              name="check-button"
              value="3"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-0">
          <b-form-group
            :label="$t('Muito bom')"
          >
            <b-form-checkbox
              v-model="valLocalizacao"
              :checked="valLocalizacao == 4"
              class="custom-control-success"
              name="check-button"
              value="4"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3 mt-1">
          <h5>
            {{ $t('Valor') }}
          </h5>
        </b-col>
        <b-col class="col-3 col-md-2">
          <b-form-group
            :label="$t('Mau')"
          >
            <b-form-checkbox
              v-model="valValor"
              :checked="valValor == 1"
              class="custom-control-danger"
              name="check-button"
              value="1"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-left-05">
          <b-form-group
            :label="$t('Médio')"
          >
            <b-form-checkbox
              v-model="valValor"
              :checked="valValor == 2"
              class="custom-control-warning"
              name="check-button"
              value="2"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-left-05">
          <b-form-group
            :label="$t('Bom')"
          >
            <b-form-checkbox
              v-model="valValor"
              :checked="valValor == 3"
              class="custom-control-primary"
              name="check-button"
              value="3"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-0">
          <b-form-group
            :label="$t('Muito bom')"
          >
            <b-form-checkbox
              v-model="valValor"
              :checked="valValor == 4"
              class="custom-control-success"
              name="check-button"
              value="4"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3 mt-1">
          <h5>
            {{ $t('Apreciação global') }}
          </h5>
        </b-col>
        <b-col class="col-3 col-md-2">
          <b-form-group
            :label="$t('Mau')"
          >
            <b-form-checkbox
              v-model="valApreciacaoglobal"
              :checked="valApreciacaoglobal == 1"
              class="custom-control-danger"
              name="check-button"
              value="1"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-left-05">
          <b-form-group
            :label="$t('Médio')"
          >
            <b-form-checkbox
              v-model="valApreciacaoglobal"
              :checked="valApreciacaoglobal == 2"
              class="custom-control-warning"
              name="check-button"
              value="2"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-left-05">
          <b-form-group
            :label="$t('Bom')"
          >
            <b-form-checkbox
              v-model="valApreciacaoglobal"
              :checked="valApreciacaoglobal == 3"
              class="custom-control-primary"
              name="check-button"
              value="3"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="col-3 col-md-2 force-padding-0">
          <b-form-group
            :label="$t('Muito bom')"
          >
            <b-form-checkbox
              v-model="valApreciacaoglobal"
              :checked="valApreciacaoglobal == 4"
              class="custom-control-success"
              name="check-button"
              value="4"
              switch
            >
              <span class="switch-icon-left" />
              <span class="switch-icon-right" />
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="6">
          <b-form-group
            :label="$t('O que mais gostou:')"
          >
            <b-form-textarea
              v-model="valOQueMaisGostou"
              placeholder=""
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('O que menos gostou:')"
          >
            <b-form-textarea
              v-model="valOQueMenosGostou"
              placeholder=""
              rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="6">
          <b-form-group
            :label="$t('Compraria este imóvel?')"
          >
            <b-form-checkbox
              v-model="valComprarImovel"
              :checked="valComprarImovel == 1"
              value="1"
            >
              {{ $t('Sim') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="valComprarImovel"
              :checked="valComprarImovel == 2"
              value="2"
            >
              {{ $t('Não') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="valComprarImovel"
              :checked="valComprarImovel == 3"
              value="3"
            >
              {{ $t('Não sei') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('Porquê')"
          >
            <b-form-textarea
              v-model="valComprarPorque"
              placeholder=""
              rows="1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('Data da visita')"
          ><p
            v-if="row.data_visita !== undefined && row.data_visita !== null"
            class="text-primary mb-0"
          >
            {{ row.data_visita.substring(0,16) || '----' }}
          </p>

          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('Data de conclusão')"
          ><p
             v-if="valDataConclusao !== null && row.data_visita !== undefined && row.data_visita !== null"
             class="text-primary mb-0"
           >
             {{ valDataConclusao.substring(0,16) }}
           </p>

            <p
              v-else
              class="text-primary mb-0"
            >
              {{ '----' }}
            </p>

          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  VBTooltip, BModal, BRow, BCol, BFormGroup, BFormCheckbox, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  props: {
    row: {
      type: Object,
      required: true,
    },
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    showMsgSuccessRequest: {
      type: Function,
      required: true,
    },
    getModalReport:
    {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modalData: [],
      processingAjax: true,
      activeRefreshListview: false,
      valQualidadeConstrucao: null,
      valAcabamentos: null,
      valExposicaoSolar: null,
      valLocalizacao: null,
      valValor: null,
      valApreciacaoglobal: null,
      valComprarImovel: null,
      valOQueMaisGostou: null,
      valOQueMenosGostou: null,
      valComprarPorque: null,
      valDataConclusao: null,
      valVisita: this.row.id_visita,
      flagProposta: false,
    }
  },
  mounted() {
  },
  methods: {
    showModal() {
      return new Promise(resolve => {
        this.$refs.modalReportVisita.show()
        resolve(true)
      })
    },
    hideModal() {
      this.$refs.modalReportVisita.hide()
    },
    closeModal(val) {
      this.$parent.showProcessoModalDetail = val
    },
    async confirmSaveModal(evt) {
      evt.preventDefault()

      this.$root.$emit('app::loading', true)

      const formData = []
      const sw070s01 = this.row.id_visita
      formData.sw073s03 = this.valQualidadeConstrucao
      formData.sw073s04 = this.valAcabamentos
      formData.sw073s05 = this.valExposicaoSolar
      formData.sw073s06 = this.valLocalizacao
      formData.sw073s07 = this.valValor
      formData.sw073s08 = this.valApreciacaoglobal

      formData.sw073s09 = this.valOQueMaisGostou
      formData.sw073s10 = this.valOQueMenosGostou
      formData.sw073s11 = this.valComprarImovel
      formData.sw073s12 = this.valComprarPorque

      formData.sw073s02 = sw070s01

      await this.$store.dispatch('crm_visitas_listview/saveModalReport', formData).then(response => {
        this.$root.$emit('app::loading', false)

        if (response.error !== 1) {
          this.hideModal()

          if (this.flagProposta === true) {
            this.closeModal(false)
            this.$parent.loadShortcutPropostas(sw070s01, response.msg)
          } else {
            if (this.valDataConclusao === null) {
              this.closeModal(false)
            } else {
              this.$parent.openModalDetailByRow(null)
            }

            this.$store.dispatch('crm_visitas_listview/fetchListView').catch(error => {
              this.showMsgErrorRequest(error)
            })
            this.showMsgSuccessRequest({ message: response.msg })
          }
        } else {
          this.showMsgErrorRequest(new Error(response.msg))
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    resetData() {
      this.valQualidadeConstrucao = null
      this.valAcabamentos = null
      this.valExposicaoSolar = null
      this.valLocalizacao = null
      this.valValor = null
      this.valApreciacaoglobal = null
      this.valComprarImovel = null
      this.valOQueMaisGostou = null
      this.valOQueMenosGostou = null
      this.valComprarPorque = null
      this.flagProposta = false
    },
    loadData() {
      this.$store.dispatch('crm_visitas_listview/loadModalReportData').then(response => {
        const formData = response

        this.valQualidadeConstrucao = formData.sw073s03
        this.valAcabamentos = formData.sw073s04
        this.valExposicaoSolar = formData.sw073s05
        this.valLocalizacao = formData.sw073s06
        this.valValor = formData.sw073s07
        this.valApreciacaoglobal = formData.sw073s08

        this.valComprarImovel = formData.sw073s11
        this.valOQueMaisGostou = formData.sw073s09
        this.valOQueMenosGostou = formData.sw073s10
        this.valComprarPorque = formData.sw073s12

        this.valDataConclusao = formData.sw073s15
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.force-padding-left-05
{
  padding-left: 0.5rem !important;
}

.force-padding-0
{
  padding:0 !important;
}

</style>
